<template>
  <CBox width="100%" :mb="4">
      <CFlex align="top" justify="center"  direction="row" flex-wrap="wrap">
          <CBox max-width="78px" v-for="(rank, i) in ranks" :key="i" :m=1>
            <CBox width="25px" height="25px" border-radius="15px" border="1px solid gray" :background="rank.color"></CBox>
            <CText whitespace="pre-wrap" pt="2" font-size="xs">{{ rank[`label_${language}`] }}</CText>
          </CBox>
      </CFlex>
  </CBox>
</template>

<script>
import { mapState } from 'vuex'
import { rank_codes } from '../config'

export default {
    props: ['isHistorical'],
    computed: {
        ...mapState(['language']),
        ranks () {
            const codes = Object.values(rank_codes[this.isHistorical])
            return codes.sort((a,b) => a.order - b.order)
        }
    }
}
</script>