<template>
    <CTabs variant="enclosed-colored" variant-color="green">
        <CTabList>
            <CTab>
                {{ title }}
            </CTab>
        </CTabList>
    </CTabs>    
</template>

<script>
export default {
    props: ['title']
}
</script>
