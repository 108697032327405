<template>
    <CBox 
        maxW="100vw"
        overflow="hidden"
        rounded="sm"
        border-width="1px"
        :p="5"
    >
        <slot />
    </CBox>
</template>

<script>
export default {

}
</script>

<style>

</style>