<template>
    <CBox :pl="3">
        <TabTitle :title="title" v-if="title" />
            <slot />
        <CGrid
            width="100%"
            :template-columns="`repeat(${colCount}, auto)`"
            :pt="3"
        >
            <CBox 
                v-for="(item, index) in rowData" :key="index"
                :bg="bg(index)" 
                :font-size="fontSize(index)" 
                :py="1"
                bb="1px solid #E2E8F0"
                :px="3"
            >
                <CText 
                    :as="isStrong(index)" 
                    :py="1" 
                    >
                    {{ item }}
                </CText>
            </CBox>
        </CGrid>
    </CBox>
</template>

<script>

import TabTitle from './TabTitle.vue'

export default {
    name: 'DataTable',
    components: {
        TabTitle
    },
    props: {
        rows: {
            type: Array
        },
        headings: {
            default: [],
        }, 
        fields : {
            default: []
        },
        title: {
            type: String,
            default: ''
        },
        hd_font_size: {
            default: 'md'
        },
        hd_bg: {
            default: "#c2ebd4"
        },
        td_font_size: {
            default: 'sm'
        },
        td_bg: {
            default: "none"
        }

    },

    methods: {
        rowColor (index) {
            return index % 2 ? '#ffffff' : '#c2ebd4'
        },

        fontSize(index) {
            return index < this.headings.length ? this.hd_font_size : this.td_font_size
        },

        bg(index) {
            return index < this.headings.length ? this.hd_bg : this.td_bg
        },

        isStrong(index) {
            return index < this.headings.length ? 'strong' : 'span'
        }
    },
    
    computed: {

        colCount() {
            return this.headings.length
        },

        rowCount() {
            return this.rows.length
        },

        rowData() {
            const rowArray = Object.values(this.rows)
            return rowArray.reduce((accum, row) => {
                for (const field of this.fields) {
                    accum.push(row[field])
                }
                return accum
            },[...this.headings])
        }

    },

}
</script>

<style>

</style>