<template>
    <CBox width="100%" align="center">
        {{ labels['map_disclaimer'][lang] }}
        <vl-map v-if="species" :controls="false" :load-tiles-while-animating="true" :data-projection="projection" style="height: 370px;" @mounted="onMapMounted" ref="map" @pointermove="onMapPointerMove">
            <vl-view :zoom="zoom" :center="center" :rotation="rotation" :min-zoom="zoom" :extent="extent"></vl-view>
            <vl-overlay v-if="currentPosition" :position="currentPosition">
                <template>
                    <c-box align="left" bg="white" :p="2" border-width="1px" rounded="md">
                        <c-text as="strong">{{ region }}</c-text>
                        <c-text>{{ `${rank_label}  ${rank_suffix}` }}</c-text>
                    </c-box>
                </template>
                </vl-overlay>
            <vl-layer-vector>
                <vl-source-vector :features="classified" :overlaps="false" ></vl-source-vector>
                <vl-style-func :factory="styleFuncFactory" />
            </vl-layer-vector>
        </vl-map>
        <map-legend :is-historical="isHistorical" />
    </CBox>
</template>

<script>

//  This is a hack to get a custom projeciton to work...https://github.com/ghettovoice/vuelayers/issues/150
import {register} from 'ol/proj/proj4'
import proj4 from 'proj4'
import {get} from 'ol/proj'
import {register as register1} from 'vuelayers/node_modules/ol/proj/proj4'
import {get as get1, transform, extent} from 'vuelayers/node_modules/ol/proj'

proj4.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs')
register(proj4)
get('EPSG:3395').setExtent([-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244]);
register1(proj4)
get1('EPSG:3395').setExtent([-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244]);

import canada from '../assets/canada_joined.json'
import { rank_codes, years, regions } from '../config'
import { Fill, Stroke, Style } from 'ol/style';
import MapLegend from './MapLegend.vue'
import { labels } from '../config'
import { mapState } from 'vuex'


function getCenterOfExtent(Extent){
    var X = Extent[0] + (Extent[2]-Extent[0])/2;
    var Y = Extent[1] + (Extent[3]-Extent[1])/2;
    return [X, Y];
}

export default 
  {
    components: { MapLegend },
    props: ['species'],
    data () {
        return { 
            labels: labels,
            projection: 'EPSG:3395',
            zoom: 3.4,
            center: [-72483,2599861],
            rotation: 0,
            features: [],
            position: [-1228140.751141687, 1697483.0105384125],
            // offset: [0,0],
            loading: false,
            extent: [-5000000, 5699861, 5000000, 22998], // BottomLeft, TopRight
            data: {},
            currentPosition: undefined,
            currentName: undefined,
            mapCursor: 'default',
            hoveredRank: undefined,
        }
    },

    mounted () {
        this.features = canada
    },

    methods: {        
        onMapPointerMove (e) {
            let hitFeature = this.$refs.map.forEachFeatureAtPixel(e.pixel, feature => feature)
            if (hitFeature) {
                this.mapCursor = 'pointer'
                this.region = hitFeature.get('region_name')
                this.rank_label = `${this.species.year}: ${hitFeature.get('label')}`
                this.rank_suffix = hitFeature.get('rank_label') ? `(${hitFeature.get('rank_label')})` : ''
                const extent = hitFeature.getGeometry().getExtent()
                const location = getCenterOfExtent(extent)
                this.currentPosition = location

            } else {
                this.mapCursor = 'default'
                this.currentPosition = this.currentName = undefined
            }
        },
        onMapClick (e) {
            this.position = e.coordinate
        },
        onMapMounted() {
            this.$refs.map.$map.getControls().pop()
        },
        styleFuncFactory () {
            return (feature) => {
                const rank = feature.get('rank')
                // Account for nf and lb where data is historical - color both features!
                const color = rank ? rank_codes[this.isHistorical][rank]['color'] : 'white'

                return [
                    new Style({
                        fill: new Fill({color}),
                        stroke : new Stroke({color: "#999999"})
                    })
                ]
            }
        },
        regionType (type) { // current, historic, all
            return regions.filter( region => region.status === type).map(region => region.prefix)
        }
    },

    computed : {
    ...mapState({
            lang: 'language',
        }),
        filteredFeatures() {

            if (this.isHistorical === 'c') {
                return this.features.filter(feature => this.regionType('current').includes(feature.properties.region_code.toLowerCase()) || this.regionType('all').includes(feature.properties.region_code.toLowerCase())) 
            } else {
                return this.features.filter(feature => this.regionType('historic').includes(feature.properties.region_code.toLowerCase()) || this.regionType('all').includes(feature.properties.region_code.toLowerCase()))
            }
        },
        classified () {
            // Filter out nl if isHistorical === 'c' ie: is current
            /// filter our NF LB if isHistorical === 'h' ie: is a historical year.
            

            const feats = this.filteredFeatures.map(feature => {
                    const region = feature.properties.region_code.toLowerCase()

                    // filter out current historical/features based on selectedYear
                    if (this.regionType('historic').includes(region) && this.isHistorical === 'c') {
                        return null
                    }


                    const field_suffix = this.isHistorical === 'c' ? '_map_rank' : '_rank'

                    return {
                        ...feature,
                        properties : {
                            ...feature.properties,
                            rank: this.species[region + field_suffix],
                            rank_label: this.species[region + '_rank'],
                            label: this.species[region + field_suffix] ? rank_codes[this.isHistorical][this.species[region + field_suffix]]['label_' + this.lang] : '',
                            region_name: regions.filter(region => region.prefix === feature.properties.region_code.toLowerCase())[0]['label_' + this.lang]
                            
                        }
                    }
                })
            return feats.length > 0 ? feats : []
        },
        isHistorical () {
            return years.find(year => year.label == this.species.year).status
        }
    }
}
</script>