import { render, staticRenderFns } from "./TabTitle.vue?vue&type=template&id=ddd293ec&"
import script from "./TabTitle.vue?vue&type=script&lang=js&"
export * from "./TabTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabs: require('@chakra-ui/vue').CTabs})
